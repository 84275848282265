<template>
  <v-app>
    <v-container fluid>
      <v-card>
        <v-card-title>Keywords</v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12" md="6" lg="2">
                <span class="d-block subtitle-2 mb-2">Location</span>
                <v-textarea
                  v-model="form.location"
                  outlined
                  clearable
                  rows="10"
                  auto-grow
                />
              </v-col>
              <v-col cols="12" md="6" lg="2">
                <span class="d-block subtitle-2 mb-2">Generic</span>
                <v-textarea
                  v-model="form.generic"
                  outlined
                  clearable
                  rows="10"
                  auto-grow
                />
              </v-col>
              <v-col cols="12" md="6" lg="2">
                <span class="d-block subtitle-2 mb-2">Type</span>
                <v-textarea
                  v-model="form.type"
                  outlined
                  clearable
                  rows="10"
                  auto-grow
                />
              </v-col>
              <v-col cols="12" md="6" lg="2">
                <span class="d-block subtitle-2 mb-2">Action</span>
                <v-textarea
                  v-model="form.action"
                  outlined
                  clearable
                  rows="10"
                  auto-grow
                />
              </v-col>
              <v-col cols="12" md="6" lg="2">
                <span class="d-block subtitle-2 mb-2">BeWords</span>
                <v-textarea
                  v-model="form.bewords"
                  outlined
                  clearable
                  rows="10"
                  auto-grow
                />
              </v-col>
              <v-col cols="12" md="6" lg="2">
                <span class="d-block subtitle-2 mb-2">Base</span>
                <v-textarea
                  v-model="form.base"
                  outlined
                  clearable
                  rows="10"
                  auto-grow
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <span class="d-block subtitle-2 mb-2">Template</span>
                <v-textarea
                  v-model="form.template"
                  outlined
                  clearable
                  rows="5"
                  auto-grow
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" md="4">
                <span class="d-block subtitle-2">Modifications</span>
                <v-row>
                  <v-col cols="12" sm="4" md="3">
                    <v-checkbox
                      v-model="modifications"
                      label="[example]"
                      color="red"
                      value="exact"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <v-checkbox
                      v-model="modifications"
                      label='"example"'
                      color="indigo"
                      value="phrase"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <v-checkbox
                      v-model="modifications"
                      label="+example"
                      color="orange"
                      value="modifier"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <v-checkbox
                      v-model="modifications"
                      label="example"
                      color="info"
                      value="broad"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <span class="d-block subtitle-2 mb-2">Settings</span>
                <v-select
                  v-model="group"
                  :items="groups"
                  item-value="key"
                  item-text="name"
                  label="Sort By"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12">
              <download-excel
                class="v-btn v-btn--contained theme--dark v-size--default green"
                :data="this.combinations"
              >
                <span class="v-btn__content">Download</span>
              </download-excel>
              <v-data-table
                :footer-props="{
                  'items-per-page-options': [
                    10,
                    20,
                    30,
                    40,
                    50,
                    60,
                    70,
                    80,
                    90,
                    100,
                    300,
                    500,
                    1000
                  ]
                }"
                :items-per-page="500"
                :headers="headers"
                :items="combinations"
                item-key="item"
                :group-by="group"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <div style="height: 50px"></div>
      </v-card>
    </v-container>
    <v-app-bar app bottom>
      <v-spacer></v-spacer>
      <v-btn :disabled="!valid" @click="generate()" color="green" dark>
        <v-icon small left>fa-cogs</v-icon>
        Generate
      </v-btn>
      <v-spacer></v-spacer>
    </v-app-bar>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    valid: false,
    rules: {
      required: value => !!value || "This field is required!"
    },
    form: {
      location: [],
      generic: [],
      type: [],
      action: [],
      bewords: [],
      base: [],
      template: "location+generic"
    },
    group: "location",
    groups: [
      { key: "location", name: "Location" },
      { key: "generic", name: "Generic" },
      { key: "type", name: "Type" },
      { key: "action", name: "Action" },
      { key: "bewords", name: "BeWords" },
      { key: "base", name: "Base" }
    ],
    modifications: ["broad"],
    headers: [
      {
        text: "Result",
        sortable: false,
        value: "result"
      }
    ],
    combinations: []
  }),
  methods: {
    generate: function() {
      // Get templates
      let templates = this.form.template.split("\n");
      let templateKeys = [];
      for (let i = 0; i < templates.length; i++) {
        templateKeys.push(
          templates[i]
            .toString()
            .trim()
            .toLowerCase()
            .split("+")
        );
      }

      // Patterns
      let patterns = [];
      for (let i = 0; i < templateKeys.length; i++) {
        if (templateKeys[i].length > 1) {
          patterns.push(this.clear(templateKeys[i]));
        }
      }

      // Create array words
      let words = [];
      for (let i = 0; i < patterns.length; i++) {
        let patternsKey = [];
        for (let j = 0; j < patterns[i].length; j++) {
          patternsKey.push(
            this.form[patterns[i][j]]
              .toString()
              .trim()
              .replace(/ /g, "^")
              .split("\n")
          );
        }
        words.push(patternsKey);
      }

      // Modifications
      let modifications = this.modifications;
      let generated = [];
      for (let i = 0; i < modifications.length; i++) {
        let results = [];
        let modification = modifications[i].toString();
        if (modification === "broad") {
          results = this.combo(modification, words);
          for (let j = 0; j < results.length; j++) {
            for (let k = 0; k < results[j].length; k++) {
              let arrayText = results[j][k].toString().split(" ");
              let name = results[j][k].toString().replace(/\^/g, "\u00A0");

              generated.push({
                result: name,
                location: this.finder(arrayText, "location"),
                generic: this.finder(arrayText, "generic"),
                type: this.finder(arrayText, "type"),
                action: this.finder(arrayText, "action"),
                bewords: this.finder(arrayText, "bewords"),
                base: this.finder(arrayText, "base")
              });
            }
          }
        }
        if (modification === "modifier") {
          results = this.combo(modification, words);
          for (let j = 0; j < results.length; j++) {
            for (let k = 0; k < results[j].length; k++) {
              let arrayText = results[j][k].toString().split(" ");
              let name = results[j][k].toString().replace(/\^/g, "\u00A0");

              generated.push({
                result: "+" + name,
                location: this.finder(arrayText, "location"),
                generic: this.finder(arrayText, "generic"),
                type: this.finder(arrayText, "type"),
                action: this.finder(arrayText, "action"),
                bewords: this.finder(arrayText, "bewords"),
                base: this.finder(arrayText, "base")
              });
            }
          }
        }
        if (modification === "exact") {
          results = this.combo(modification, words);
          for (let j = 0; j < results.length; j++) {
            for (let k = 0; k < results[j].length; k++) {
              let arrayText = results[j][k].toString().split(" ");
              let name = results[j][k].toString().replace(/\^/g, "\u00A0");

              generated.push({
                result: "[" + name + "]",
                location: this.finder(arrayText, "location"),
                generic: this.finder(arrayText, "generic"),
                type: this.finder(arrayText, "type"),
                action: this.finder(arrayText, "action"),
                bewords: this.finder(arrayText, "bewords"),
                base: this.finder(arrayText, "base")
              });
            }
          }
        }
        if (modification === "phrase") {
          results = this.combo(modification, words);
          for (let j = 0; j < results.length; j++) {
            for (let k = 0; k < results[j].length; k++) {
              let arrayText = results[j][k].toString().split(" ");
              let name = results[j][k].toString().replace(/\^/g, "\u00A0");

              generated.push({
                result: '"' + name + '"',
                location: this.finder(arrayText, "location"),
                generic: this.finder(arrayText, "generic"),
                type: this.finder(arrayText, "type"),
                action: this.finder(arrayText, "action"),
                bewords: this.finder(arrayText, "bewords"),
                base: this.finder(arrayText, "base")
              });
            }
          }
        }
      }
      this.combinations = generated;
    },
    clear: function(array) {
      let data = [];
      for (let j = 0; j < array.length; j++) {
        data.push(array[j].toString().trim());
      }
      return data;
    },
    combo: function(modification, words) {
      let results = [];
      for (let i = 0; i < words.length; i++) {
        let output = words[i].reduce((acc, cu) => {
          let ret = [];
          acc.map(obj => {
            cu.map(obj_1 => {
              if (modification === "modifier") {
                ret.push(obj + " +" + obj_1);
              } else {
                ret.push(obj + " " + obj_1);
              }
            });
          });
          return ret;
        });
        results.push(output);
      }
      return results;
    },
    finder: function(arrayText, key) {
      let form = this.form[key]
        .toString()
        .replace(/ /g, "^")
        .split("\n");
      for (let i = 0; i < arrayText.length; i++) {
        let text = arrayText[i].toString().replace(/\+/g, "");
        if (form.indexOf(text) > -1) {
          return arrayText[i]
            .toString()
            .replace(/\^/g, "\u00A0")
            .replace(/\+/g, "");
        }
      }
    }
  }
};
</script>
